import React from 'react';
import { add as dateAdd, differenceInMonths, format as dateFormat } from 'date-fns';
import {NPER, calculateCumulativeInterest, calculateMonthlyPayment} from './loan-functions'
import { Money } from './Money';
import { Card } from './Card';

export interface LoanType {
  years: number
  rate: number
}

interface LoanListProps {
  homeCosts: number[]
  downPayment: number
  normalizedPayment: number
  loanStartDate: Date
  loanTypes: LoanType[]
}

export const LoanList: React.FC<LoanListProps> = ({homeCosts, downPayment, normalizedPayment, loanStartDate, loanTypes}) => {
  return (
    <>
      {homeCosts.map((cost) => (
        loanTypes.map((loanType) => {
          let loanAmount = Math.max(cost - downPayment, 0)
          let monthlyRate = loanType.rate / 12
          let paymentCount = loanType.years * 12
          let monthlyPayment = Math.round(calculateMonthlyPayment({loanAmount, monthlyRate, paymentCount}))
          let payoffDate = dateAdd(loanStartDate, { years: loanType.years })
          let totalInterest = calculateCumulativeInterest({loanAmount, monthlyPayment, paymentCount})
          let additionalPayments = normalizedPayment - monthlyPayment

          let cardClasses = ["mb-2", "flex", "items-center", "max-w-3xl", "mx-auto"]
          
          let additionalPaymentData: {monthlyPayment: number, payoffDisplay: string, earlyPayoffDisplay: string, interestPaid: number} | undefined
          if (additionalPayments >= 0) {
            let paymentCountWithAdditionalPayments = NPER(monthlyRate, -normalizedPayment, loanAmount)
            let payoffDateWithAdditionalPayments = dateAdd(loanStartDate, {months: paymentCountWithAdditionalPayments})
            let totalInterestWithAdditionalPayments = Math.round(calculateCumulativeInterest({loanAmount, monthlyPayment: normalizedPayment, paymentCount: paymentCountWithAdditionalPayments}))

            let monthsDifference = differenceInMonths(payoffDate, payoffDateWithAdditionalPayments)
            let yearsEarly = Math.floor(monthsDifference / 12)
            let monthsEarly = Math.floor(monthsDifference % 12)
            let earlyDisplaySegments = []
            if (yearsEarly > 0) {
              earlyDisplaySegments.push(`${yearsEarly} yrs`)
            }

            if (monthsEarly > 0) {
              earlyDisplaySegments.push(`${monthsEarly} mos`)
            }

            additionalPaymentData = {
              monthlyPayment: normalizedPayment,
              payoffDisplay: dateFormat(payoffDateWithAdditionalPayments, "LLL y"),
              earlyPayoffDisplay: `${earlyDisplaySegments.join(", ")} early`,
              interestPaid: totalInterestWithAdditionalPayments
            }
          } else {
            // If there are no additional payments, show that this loan is not affordable 
            cardClasses.push("border-2")
            cardClasses.push("border-red-500")
          }

          return (
            <Card className={cardClasses.join(" ")}>
              <div className="border-r-2 p-2 py-4">
                <Money value={cost} short={true} className="block text-xl" />
                <span className="text-xs text-gray-500 block">
                  LOAN:&nbsp;
                  <Money value={loanAmount} short={true} />
                </span>
                <span className="block text-xs text-gray-500">{loanType.years} yrs</span>
              </div>

              <div className="flex flex-grow justify-evenly text-right">
                <div className="mr-2">
                  <div className="text-sm text-gray-500 uppercase">
                    Payment
                  </div>
                  <div>
                    <Money value={monthlyPayment} />
                    <span className="text-gray-400 text-xs hidden sm:inline">/mo</span>
                  </div>
                  
                  {additionalPaymentData && (
                    <div className="max-payment-info">
                      <Money value={additionalPaymentData.monthlyPayment} />
                      <span className="text-gray-400 text-xs hidden sm:inline">/mo</span>
                    </div>
                  )}
                </div>
                
                <div className="mr-2">
                  <div className="text-sm text-gray-500 uppercase">
                    Payoff Date
                  </div>
                  <div>{dateFormat(payoffDate, "LLL y")}</div>
                  
                  {additionalPaymentData && (
                    <div>
                      <span>{additionalPaymentData.payoffDisplay}</span>
                      <span className="block text-green-500 text-sm">{additionalPaymentData.earlyPayoffDisplay}</span>
                    </div>
                  )}
                </div>
                
                <div className="">
                  <div className="text-sm text-gray-500 uppercase">
                    Interest Paid
                  </div>
                  <div><Money value={totalInterest} /></div>
                  
                  {additionalPaymentData && (
                    <div>
                      <Money value={additionalPaymentData.interestPaid} />
                      <div className="text-green-500 text-sm">
                        <Money value={totalInterest - additionalPaymentData.interestPaid} />
                        &nbsp;saved
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          )
        })
      ))}
    </>
  );
}