import React, { useState } from 'react';
import { Label } from './Label';
import { Card } from './Card';
import { Input } from './Input';
import { FormGroup } from './FormGroup';
import NumberFormat from 'react-number-format';
import { LoanList } from './LoanList';

function App() {
  const [userDownPayment, setUserDownPayment] = useState<number | undefined>(10000)
  const [maxMonthlyPayment, setMaxMonthlyPayment] = useState(2000)
  const [mortgageRateFifteen, setMortgageRateFifteen] = useState(2.16)
  const [mortgageRateThirty, setMortgageRateThirty] = useState(3)

  const downPayment = userDownPayment ?? 0

  const monthlyMortgageRate = mortgageRateThirty / 100 / 12

  const maxHomeValue = (maxMonthlyPayment / monthlyMortgageRate) * (1 - (1/Math.pow(1+monthlyMortgageRate, 360)))
  const roundedMaxHomeValue = Math.floor(maxHomeValue / 10000) * 10000
  let homeCosts: number[] = []
  let curVal = roundedMaxHomeValue
  while (curVal > 0) {
    homeCosts.push(curVal)
    curVal -= 10000
  }

  const loanStartDate = new Date()

  const loanTypes = [
    {
      years: 30,
      rate: mortgageRateThirty / 100
    },
    {
      years: 15,
      rate: mortgageRateFifteen / 100
    }
  ]

  return (
    <>
      <div className="bg-white pl-2 pr-2 py-3 font-sans shadow-xl md:flex items-center">
        <h1 className="text-4xl">Mortgage Projections</h1>

        <div className="ml-auto">
          <span>Made by <a className="underline" href="https://github.com/willtcarey">Will Carey</a> for saving 💰</span>
        </div>
      </div>
      <div className="bg-green-500 font-sans">
        <div className="p-2 max-w-md mx-auto mb-6">
          <Card>
            {/* <FormGroup>
              <Label>Home Prices</Label>
              <div className="flex items-center">
                <NumberFormat value={userMinHomeValue} customInput={Input} thousandSeparator={true} prefix={'$'} onValueChange={({floatValue}) => setUserMinHomeValue(floatValue)} />
                <span className="mx-2">to</span>
                <NumberFormat value={userMaxHomeValue} customInput={Input} thousandSeparator={true} prefix={'$'} onValueChange={({floatValue}) => setUserMaxHomeValue(floatValue)} />
              </div>
            </FormGroup> */}

            <FormGroup>
              <Label>Down Payment</Label>
              <NumberFormat value={userDownPayment} customInput={Input} thousandSeparator={true} prefix={'$'} onValueChange={({floatValue}) => setUserDownPayment(floatValue)} />
            </FormGroup>

            <FormGroup>
              <Label>Max Monthly Payment</Label>
              <NumberFormat value={maxMonthlyPayment} customInput={Input} thousandSeparator={true} prefix={'$'} onValueChange={({floatValue}) => setMaxMonthlyPayment(floatValue ?? 0)} />
              <span className="text-sm text-gray-500">
                Put in the max monthly mortgage payment you could comfortably afford each month.
                Here's the exciting part. Due to the  magic of...math, you can actually save a whole lotta money in interest paid by making extra payments towards your principal. We'll show you how much sooner you can pay off your loan and how much money you will save by putting in extra money (up to this amount) towards your loan every month.
              </span>
            </FormGroup>

            <FormGroup>
              <Label>30 year mortgage rate</Label>
              <NumberFormat value={mortgageRateThirty} customInput={Input} suffix="%" onValueChange={({floatValue}) => setMortgageRateThirty(floatValue ?? 0)} />
            </FormGroup>

            <FormGroup>
              <Label>15 year mortgage rate</Label>
              <NumberFormat value={mortgageRateFifteen} customInput={Input} suffix="%" onValueChange={({floatValue}) => setMortgageRateFifteen(floatValue ?? 0)} />
            </FormGroup>
          </Card>
        </div>

        <div className="m-2 mx-0 sm:mx-2 overflow-x-scroll">
          {homeCosts.length > 0 ? (
            <div className="w-max sm:w-auto max-w-5xl mx-auto">
              <LoanList
                homeCosts={homeCosts}
                loanTypes={loanTypes}
                downPayment={downPayment}
                normalizedPayment={maxMonthlyPayment}
                loanStartDate={loanStartDate}
              />
            </div>
          ) : (
            <Card className="text-center">
              <h1 className="text-lg">Please fill out the form above to see mortgage projections.</h1>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}

export default App;
