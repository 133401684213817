import React from 'react';

export const Money: React.FC<{value: number, short?: boolean, className?: string}> = (props) => {
  let displayValue:string
  
  if (props.short && props.value >= 10000) {
    displayValue = `${(props.value / 1000).toLocaleString()}k`
  } else {
    displayValue = props.value.toLocaleString()
  }

  return (
    <span className={`font-mono ${props.className}`}>${displayValue}</span>
  )
}